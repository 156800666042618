





























































































































.css_time {
  position: absolute;
  bottom: 0px;
  right: 24px;
}
@import "../../styles/news";
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      height: 500px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/.newsListNav {
    height: 6rem;
    .ListNav/deep/ {
      margin-right: 15rem;
    }
  }
}
